<template>
  <div class="list-tool-bar">
    <div class="action" v-if="$userRole(['SuperAdmin'])">
      <el-button @click="emit('onCreate')" type="primary">{{ t('table.create') }}</el-button>
      <el-button @click="emit('onDelete')" type="primary" plain>{{ t('form.delete') }}</el-button>
    </div>
    <div class="search">
      <div class="search_item">
        <el-input
          v-model="searchData.search"
          :prefix-icon="Search"
          :placeholder="t('product.product_name')" 
          @input.native="doSearch"
          clearable
          class="search-input" />
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, inject } from 'vue'
import { Search } from '@element-plus/icons-vue'

const t = inject('t')
const emit = defineEmits(['getList', 'onCreate', 'onDelete'])
const searchData = ref({
  page: 1
})

// 搜索事件
const doSearch = () => {
  for (let key in searchData.value) {
    if (searchData.value[key] === '') {
      delete searchData.value[key]
    }
  }
  emit('getList', searchData.value)
}
</script>
