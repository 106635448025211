<template>
  <el-dialog
    v-model="winShow"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :title="title"
    width="900px"
    @close="closeWin">
    <custem-form
      ref="form"
      :loading="formLoading"
      :formData="formData"
      :formRules="formRules"
      @change="change"
    />
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="submit" :disabled="formLoading" :loading="submitLoading" type="primary">{{ t('form.submit') }}</el-button>
        <el-button @click="closeWin">{{ t('form.cancel') }}</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, computed, inject, reactive, watch, nextTick } from 'vue'
import { rules } from '@/utils/rules'
import { productManagement } from '@/utils/api'
import { ElMessage } from 'element-plus'

const t = inject('t')
const winShow = ref(false)
const edit_id = ref(null)
const formLoading = ref(false)
const submitLoading = ref(false)
const form = ref()
const emit = defineEmits(['getList'])
const params = ref(['auth_address', 'auth_name', 'auth_password', 'is_automatic'])
const deploymentText = ref({
  1001: 'SAAS',
  1002: 'STDA',
  1003: 'COEM',
})
const categoriesText = ref({
  1001: 'HYM',
  1002: 'BDR',
  1003: 'EYB',
  1004: 'CSB'
})
// 产品型号校验规则
const productLabelRules = async (rule, modelVal, callback) => {
  if (modelVal.value !== '0') {
    if (!!!formData.product_category_id.value) {
      callback(new Error(t('product.select_product_category')))
      modelVal.value = '0'
      return
    }
    const res = await productManagement.ProductList({
      filter_label: modelVal.value,
      filter_product_category_id: formData.product_category_id.value
    })
    if (res.total > 0) {
      callback(new Error(t('product.has_model')))
    } else {
      callback()
    }
  } else {
    callback()
  }
}
const formRules = reactive({
  product_deployment_id: rules,
  type_abbreviation: rules,
  product_category_id: [
    { required: true },
    { validator: productLabelRules }
  ],
  category_abbreviation: rules,
  name: rules,
  full_name: rules,
  version: rules,
  code: rules,
  product_label: [
    { required: true },
    { validator: productLabelRules }
  ],
  auth_address: rules,
  auth_name: rules,
  auth_password: rules,
  is_automatic: rules
})
const formData = reactive({
  product_deployment_id: { // 产品类型
    label: t('product.product_type'),
    type: 'select',
    options: [
      {
        label: t('product.saas'),
        value: 1001
      },
      {
        label: t('product.stda'),
        value: 1002
      },
      {
        label: t('product.coem'),
        value: 1003
      }
    ]
  },
  type_abbreviation: { // 类型缩写
    type: 'select',
    label: t('product.type_abbreviation'),
    options: [
      {
        label: 'SAAS',
        value: 1001
      },
      {
        label: 'STDA',
        value: 1002
      },
      {
        label: 'COEM',
        value: 1003
      }
    ],
    disabled: true
  },
  product_category_id: { // 产品大类
    type: 'select',
    label: t('product.product_category')
  },
  category_abbreviation: { // 大类缩写
    type: 'select',
    label: t('product.category_abbreviation'),
    disabled: true,
    options: [
      { label: 'HYM', value: 1001 },
      { label: 'BDR', value: 1002 },
      { label: 'EYB', value: 1003 },
      { label: 'CSB', value: 1004 }
    ]
  },
  name: { // 产品简称
    type: 'text',
    label: t('product.product_abbreviation')
  },
  full_name: { // 产品全称
    type: 'text',
    label: t('product.product_full_name')
  },
  version: { // 版本
    type: 'text',
    label: t('product.version')
  },
  code: { // 产品编码
    type: 'text',
    label: t('product.product_code')
  },
  product_label: { // 产品型号
    type: 'select',
    label: t('product.product_model'),
    value: '0',
    options: [
      { // 通用产品
        label: t('product.general'),
        value: '0'
      },
      { // 内部研发产品
        label: t('product.in_house'),
        value: '1'
      },
      { // 扫码试用产品
        label: t('product.scan_to_try'),
        value: '2'
      }
    ]
  },
  comment: {
    type: 'textarea',
    label: t('user.notes')
  },
  custom: { // 自定义设置
    type: 'switch',
    label: t('product.custom_setting'),
    hidden: true
  },
  auth_address: { // 产品鉴权地址
    type: 'text',
    label: t('product.auth_address'),
    hidden: true
  },
  auth_name: { // 管理员用户名
    type: 'text',
    label: t('product.admin_username'),
    hidden: true
  },
  auth_password: { // 管理员密码
    type: 'text',
    label: t('product.admin_password'),
    hidden: true
  },
  is_automatic: { // 是否允许自动下发授权
    type: 'radio',
    value: false,
    hidden: true,
    label: t('product.allow_auto'),
    options: [
      { id: true, name: t('user.allow') },
      { id: false, name: t('user.not_allow') }
    ]
  }
})
// 获取产品大类列表
const getCategoriesList = async () => {
  formData.product_category_id.loading = true
  formData.product_category_id.value = null
  formData.product_category_id.options = []
  try {
    const res = await productManagement.ProductCategoryList({
      filter_deployment_id: formData.product_deployment_id.value
    })
    res.items.forEach(item => {
      formData.product_category_id.options.push({
        name: item.category.name,
        id: item.category.id
      })
    })
    formData.product_category_id.value = formData.product_category_id.options[0].id // 设置产品大类默认值
    formData.category_abbreviation.value = formData.product_category_id.options[0].id // 设置产品大类缩写默认值
    formData.product_category_id.loading = false
  } catch (error) {
    formData.product_category_id.loading = false
  }
}
// 表单chang事件
const change = ({ item, prop, event }) => {
  if (prop === 'product_deployment_id') {
    formData.custom.hidden = item.value !== 1003
    formData.type_abbreviation.value = item.value
  } else if (prop === 'product_category_id') {
    formData.category_abbreviation.value = item.value
  } else if (prop === 'custom') {
    params.value.forEach(ele => {
      if (formData[ele]) formData[ele].hidden = !item.value
      if (ele === 'is_automatic') {
        formData[ele].value = false
      } else {
        if (formData[ele]) formData[ele].value = null
      }
    })
  }
}
// 获取产品详情
const getProductDetail = async () => {
  formLoading.value = true
  try {
    const res = await productManagement.ProductDetail(edit_id.value)
    await nextTick()
    for (const key in res) {
      if (params.value.includes(key)) {
        if (formData[key]) formData[key].value = res.params[key]
      } else if (key === 'deployment') { // 产品类型
        formData.product_deployment_id.value = res[key].id // 设置产品类型
        formData.type_abbreviation.value = res[key].abbreviation // 设置产品类型缩写
        formData.custom.hidden = parseInt(res[key].id) !== 1003
        formData.custom.value = parseInt(res[key].id) === 1003
        params.value.forEach(item => {
          formData[item].hidden = parseInt(res[key].id) !== 1003
          formData[item].value = parseInt(res[key].id) === 1003 && data.params[item] || null
        })
      } else if (key === 'label') { // 产品型号
        formData.product_label.value = String(res[key])
      } else if (key === 'category') { // 产品大类
        formData.product_category_id.value = res[key].id
        formData.category_abbreviation.value = res[key].abbreviation
      } else {
        if (formData[key]) formData[key].value = res[key]
      }
    }
    formLoading.value = false
  } catch (error) {
    formLoading.value = false
  }
}
// 弹框标题
const title = computed(() => {
  return edit_id.value ? t('product.edit') : t('product.create')
})
// 表单提交
const submit = async () => {
  const res = await form.value.submitForm()
  if (!res) return
  const postData = {
    params: {
      auth_type: 'password'
    },
    product_license_id: 1
  }
  for (const key in formData) {
    if (formData[key].value !== '' && formData[key].value !== undefined && !params.value.includes(key)) {
      if (key === 'category_abbreviation') {
        postData[key] = categoriesText.value[formData[key].value]
      } else if (key === 'type_abbreviation') {
        postData[key] = deploymentText.value[formData[key].value]
      } else {
        postData[key] = formData[key].value
      }
    }
  }
  params.value.forEach(item => {
    if (formData[item].value !== '' && formData[item].value !== undefined) postData.params[item] = formData[item].value
  })
  submitLoading.value = true
  try {
    if (!!!edit_id.value) {
      // 新增
      await productManagement.ProductCreate(postData)
      ElMessage.success(t('table.add_success'))
    } else {
      // 编辑
      await productManagement.ProductUpdate(edit_id.value, postData)
    }
    submitLoading.value = false
    closeWin()
    emit('getList')
  } catch (error) {
    submitLoading.value = false
  }
}
// 打开弹框
const openWin = async (data = null) => {
  winShow.value = true
  await nextTick()
  formData.product_category_id.value = null
  form.value.clearForm()
  if (data) {
    edit_id.value = data
    getProductDetail()
  }
}
const closeWin = () => {
  winShow.value = false
}

watch(() => formData.product_deployment_id.value, val => {
  if (!val) return
  getCategoriesList()
}, { deep: true, immediate: true })
defineExpose({
  openWin
})
</script>